@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

@import "variables/portal";
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/css/all.css";

@import "mixins/background";
@import "mixins/badge";
@import "mixins/button";
@import "mixins/modal";
@import "mixins/switch";
@import "mixins/tabs";
@import "mixins/theme";
@import "mixins/wizard";

@import "components/accordion";
@import "components/alert";
@import "components/avatar";
@import "components/badge";
@import "components/buttons";
@import "components/card";
@import "components/chart";
@import "components/chat";
@import "components/content";
@import "components/dropdown";
@import "components/feather";
@import "components/footer";
@import "components/forms";
@import "components/hamburger";
@import "components/landing";
@import "components/link";
@import "components/loading";
@import "components/main";
@import "components/modal";
@import "components/navbar";
@import "components/progress";
@import "components/pagination";
@import "components/reboot";
@import "components/root";
@import "components/settings";
@import "components/sidebar";
@import "components/sizing";
@import "components/tables";
@import "components/tabs";
@import "components/timeline";
@import "components/type";
@import "components/wizard";
@import "components/wrapper";

@import "utilities/background";
@import "utilities/borders";
@import "utilities/cursors";
@import "utilities/font";
@import "utilities/overflow";
@import "utilities/position";
@import "utilities/width";
@import "utilities/z-index";
@import "utilities/display";

@import "thridparties/ngx-toastr";
@import "thridparties/range-slider";

.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}

.relation-table-col-1 {
	width: 50px;
}

.relation-table-col-2 {
	width: 200px;
}

.relation-table-col-4 {
	width: 200px;
}

.relation-table-col-5 {
	width: 130px;
}

.relation-table-col-6, .relation-table-col-7 {
	width: 120px;
}

.relation-table-col-8 {
	width: 100px;
}

.reports-table-header {

	border-top-width: 1px;
}

.reports-table-col-1 {
	width: 10%;
	border-right-width: 1px;
	border-left-width: 1px;
}

.reports-table-col-2 {
	width: 15%;
	border-right-width: 1px;
}

.reports-table-col-months {
	width: 5%;
	text-align: center;
}

.reports-table-col-total {
	width: 5%;
	border-left-width: 1px;
	border-right-width: 1px;
	text-align: center;
}

.reports-td-hover:hover {
	border-radius: 10px;
	background-color: #d3e3ff !important;
}

.reports-tooltip {
	position: fixed;
	background-color: #fcfcfc;
	border-style: solid;
	border-color: #858383;
	border-width: 1px;
	border-radius: 4px;
	padding: 3px 6px;
	font-size: 13px;
	margin-top: 5px;
	transform: translateX(-50%);

	&::before {
		content: "";
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid black;
		position: absolute;
		left: calc(50% - 5px);
		top: -5px;
	}
}

.matches-table-col-1, .matches-table-col-2, .matches-table-col-3 {
	width: 15%;
}

.matches-table-col-4 {
	width: 20%;
}

.matches-table-col-5 {
	width: 20%;
}

.matches-table-col-6 {
	width: 5%;
}

.matches-table-match {
	color: green;
}

.matches-table-no-match {
	color: red;
}

.matches-table-almost-match {
	color: orange;
}


.company-table-col-1, .company-table-col-2, .company-table-col-2 {
	width: 30%;
}

.company-table-col-4 {
	width: 10%;
}

.contract-table-col-1 {
	width: 250px;
}

.contract-table-col-3 {
	width: 200px;
}

.contract-table-col-4 {
	width: 90px;
}

.contract-table-col-5 {
	width: 140px;
}

.contract-table-col-6, .contract-table-col-7 {
	width: 120px;
}

.contract-table-col-8 {
	width: 100px;
}

.company-demand-table-col-1 {
	width: 20%;
}

.company-demand-table-col-2, .company-demand-table-col-3, .company-demand-table-col-4, .company-demand-table-col-5 {
	width: 20%;
}

.company-demand-table-col-6 {
	width: 50px;
}

.complaint-table-col-1 {
	width: auto;
}

.complaint-table-col-2 {
	width: 200px;
}

.complaint-table-col-3 {
	width: 140px;
}

.complaint-table-col-4 {
	width: 42px;
}

.heating-table-col-1 {
	width: auto;
}

.heating-table-col-2 {
	width: 200px;
}

.heating-table-col-3 {
	width: 140px;
}

.heating-table-col-4 {
	width: 42px;
}


div.ngb-dp-day {
	.btn-light.active, .btn-primary {
		background-color: $hubbs-red !important;
	}
}

.ng-select-badge-multiple {
	&.ng-value-icon {
		margin-right: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&.ng-value-label {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.nav-item {
	.indicator {
		background: $primary;
		box-shadow: $box-shadow;
		border-radius: 50%;
		display: block;
		height: 20px;
		width: 20px;
		padding-top: 2.5px;
		position: absolute;
		top: -10px;
		right: -10px;
		text-align: center;
		transition: top 0.1s ease-out;
		font-size: 0.65rem;
		color: $white;
		user-select: none;
	}

	&:hover .indicator {
		top: -14px;
	}

	a:focus {
		outline: 0;
	}
}

.memo-column {
	white-space: nowrap;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-dashboard .table,
.card-dashboard-lg .table {
	table-layout: fixed;
	word-wrap: break-word;
	white-space: nowrap;
	width: 100%;
}

.card-title {
	text-align: left;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hubbs-progress-bar[value] {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 20px;
}

.hubbs-progress-bar[value]::-webkit-progress-bar {
	background-color: #cccccc;
	border-radius: 2px;
}

.hubbs-progress-bar[value]::-webkit-progress-value {
	background-color: $hubbs-light;
	border-radius: 2px;
	background-size: 35px 20px, 100% 100%, 100% 100%;
}

.hubbs-label {
	opacity: 0.65;
	transform: translateX(-6%) scale(0.85);
}

.hubbs-dashboard-alert {
	background-color: #ffdddd;
}

.hubbs-row-alert {
	background-color: #ffdddd !important;
}

.hubbs-row-warning {
	background-color: #fff6dd !important;
}

.hubbs-row-success {
	background-color: #ddffdd !important;
}

.form-floating > label {
	height: 40px;
	overflow: hidden;
}

.resize-none {
	resize: none;
}

.overflow-hidden {
	max-width: 310px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.overflow-hidden:hover {
	white-space: normal;
	overflow: visible;
	z-index: 100;
	background-color: white;
	position: absolute;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.iframe-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.iframe-container iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.mail-history-table-col-3 {
	width: 300px;
}

.w-auto {
	width: auto;
}

.w-col-110 {
	width: 110px;
}

.w-col-200 {
	width: 200px;
}

.w-col-125 {
	width: 125px;
}

.w-col-150 {
	width: 150px;
}

.dashboard-column {
	overflow: hidden;
	text-overflow: ellipsis;
}