$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-size-base: 0.8125rem !default;
$font-size-lg: 0.95rem !default;
$font-size-sm: 0.825rem !default;
$headings-font-weight: 500 !default;
$small-font-size: 80% !default;

$spacer: 1rem !default;

$spacers: (
		0: 0,
		1: (
				$spacer * 0.25,
		),
		2: (
				$spacer * 0.5,
		),
		3: $spacer,
		4: (
				$spacer * 1.5,
		),
		5: (
				$spacer * 3,
		),
		6: (
				$spacer * 4.5,
		),
		7: (
				$spacer * 6,
		),
) !default;

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1440px,
) !default;

$grid-gutter-width: 24px !default;

$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1200px,
) !default;

$container-padding-x: 0.75rem !default;

$enable-validation-icons: false !default;
$enable-rfs: false !default;
$enable-negative-margins: true !default;
$min-contrast-ratio: 3 !default;

$transition-base: all 0.35s ease-in-out !default;

$hubbs: #3c6573 !default;
$hubbs-red: #e31354 !default;
$hubbs-light: #18919e !default;
$blue: #3f80ea !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #fd7e14 !default;
$yellow: #e5a54b !default;
$green: #4bbf73 !default;
$teal: #20c997 !default;
$cyan: #1f9bcf !default;

// Relation status colors
$vacancy: #ea9999 !default;
$denouncedrelation: #ffe59a !default;
$denouncedrelationcompany: #ffe59a !default;
$denouncedcompany: #fbbc11 !default;
$rented: #b7e1cd !default;
$denouncedcompanyrented: #daead4 !default;
$tobedelivered: #a4c2f4 !default;
$gone: #aaaaaa !default;
$tobedeliveredrented: #d5a6bd !default;
$onhold: #ffe59a !default;
$boilerMaintenance: #ffe59a !default;
$smokeDetectors: #a4c2f4 !default;

$white: #fff !default;
$gray-100: #f4f7f9 !default;
$gray-200: #e2e8ee !default;
$gray-300: #dee6ed !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #0a0a0a !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: lighten($gray-200, 4%) !default;
$dark: #293042 !default;
$black: #000000 !default;

$theme-colors: (
		'primary': $primary,
		'secondary': $secondary,
		'success': $success,
		'info': $info,
		'warning': $warning,
		'danger': $danger,
		'light': $light,
		'dark': $dark,
		'black': $black,
		'hubbs': $hubbs,
		'hubbs-red': $hubbs-red,
		'hubbs-red-sync': $hubbs-red,
		'hubbs-light': $hubbs-light,
		'hubbs-relation-vacancy': $vacancy,
		'hubbs-relation-denouncedrelation':$denouncedrelation,
		'hubbs-relation-denouncedrelationcompany':$denouncedrelationcompany,
		'hubbs-relation-denouncedcompany':$denouncedcompany,
		'hubbs-relation-denouncedcompanyrented':$denouncedcompanyrented,
		'hubbs-relation-rented':$rented,
		'hubbs-relation-tobedelivered':$tobedelivered,
		'hubbs-relation-gone':$gone,
		'hubbs-relation-tobedeliveredrented':$tobedeliveredrented,
		'hubbs-relation-onhold':$onhold,
		'hubbs-dashboard-vacancy': $vacancy,
		'hubbs-dashboard-denouncedcompany':$denouncedcompany,
		'hubbs-dashboard-denouncedowner':$denouncedrelation,
		'hubbs-dashboard-denouncedcompanyrented':$denouncedcompanyrented,
		'hubbs-dashboard-expiringcontracts':$denouncedcompany,
		'hubbs-dashboard-totalrented':$rented,
		'hubbs-dashboard-tobedelivered':$tobedelivered,
		'hubbs-dashboard-gone':$gone,
		'hubbs-dashboard-tobedeliveredrented':$tobedeliveredrented,
		'hubbs-dashboard-onhold':$onhold
) !default;

$custom-colors: (
		primary-dark: darken($primary, 15%),
		primary-light: lighten($primary, 15%),
) !default;

$static-white: #fff !default;
$static-gray-100: #f8f9fa !default;
$static-gray-200: #e9ecef !default;
$static-gray-300: #dee2e6 !default;
$static-gray-400: #ced4da !default;
$static-gray-500: #adb5bd !default;
$static-gray-600: #6c757d !default;
$static-gray-700: #495057 !default;
$static-gray-800: #0a0a0a !default;
$static-gray-900: #212529 !default;
$static-black: #000 !default;
$static-dark: #293042 !default;

$body-bg: #f7f9fc !default;
$body-color: $gray-800 !default;

$link-decoration: none !default;
$link-hover-decoration: underline !default;

$border-width: 1px !default;

$line-height-base: 1.625 !default;
$line-height-lg: 1.625 !default;
$line-height-sm: 1.625 !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$headings-color: $gray-700 !default;

$text-muted: lighten($gray-600, 3);
$text-bold-color: $gray-700 !default;

$progress-height: 1rem !default;
$progress-height-sm: 0.5rem !default;
$progress-height-lg: 1.5rem !default;

$box-shadow-sm: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.05) !default;
$box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05) !default;
$box-shadow-lg: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05) !default;

$border-radius-sm: 0.1rem !default;
$border-radius: 0.2rem !default;
$border-radius-lg: 0.3rem !default;

$navbar-padding-y: $spacer * 0.875 !default;
$navbar-padding-x: $spacer * 1.25 !default;
$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-border-bottom: 0 !default;
$navbar-box-shadow: 0 0 2rem 0 rgba($dark, 0.1) !default;
$navbar-bg: $white !default;

$navbar-brand-padding-y: $spacer * 0.875 !default;
$navbar-brand-padding-x: 0 !default;
$navbar-brand-color: $gray-100 !default;
$navbar-brand-font-weight: $font-weight-bold !default;
$navbar-brand-font-size: 1.15rem !default;
$navbar-brand-icon-color: $primary !default;

$navbar-input-bg: darken($body-bg, 1%) !default;

$alert-padding-y: 0.95rem !default;
$alert-padding-x: 0.95rem !default;
$alert-border-width: 0 !default;

$input-bg: $white !default;
$input-disabled-bg: $white !default;
$input-color: $gray-700 !default;
$input-btn-border-width: $border-width !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-focus-width: 0.2rem !default;
$input-btn-padding-y: 0.25rem !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-y-lg: 0.35rem !default;
$input-btn-padding-x: 1.5rem !default;
$input-btn-padding-x-sm: 1.5rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-item-padding-y: 0.35rem !default;
$dropdown-item-padding-x: 1.5rem !default;

$card-border-radius: 0rem !default;
$card-inner-border-radius: $card-border-radius !default;
$card-border-width: 0 !default;
$card-border-color: transparent !default;
$card-cap-bg: $white !default;
$card-shadow: 0 0 2.875rem 0 rgba($dark, 0.15) !default;
$card-title-font-weight: $font-weight-bold !default;
$card-title-color: $gray-700 !default;
$card-spacer-y: $spacer * 1.25 !default;
$card-spacer-x: $spacer * 1.25 !default;
$card-cap-padding-y: $spacer !default;
$card-cap-padding-x: $card-spacer-x !default;

$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.75rem !default;
$table-cell-padding-y-sm: 0.3rem !default;
$table-cell-padding-x-sm: 0.3rem !default;
$table-striped-bg: $gray-100 !default;

$sidebar-width: 220px !default;
$sidebar-transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out !default;
$sidebar-bg: (
		'default': #293042,
		'colored': #2d6bcf,
		'dark': #293042,
		'light': $static-white,
) !default;

$sidebar-brand-padding-y: 1.15rem !default;
$sidebar-brand-padding-x: 1.5rem !default;
$sidebar-brand-font-weight: $font-weight-bold !default;
$sidebar-brand-font-size: 1.125rem !default;
$sidebar-brand-icon-color: (
		'default': lighten($primary, 2.5%),
		'colored': $static-white,
		'dark': lighten($primary, 2.5%),
		'light': darken($primary, 5%),
) !default;
$sidebar-brand-icon-top-color: (
		'default': lighten($primary, 12.5%),
		'colored': rgba($static-white, 0.66),
		'dark': lighten($primary, 12.5%),
		'light': lighten($primary, 15%),
) !default;
$sidebar-brand-color: (
		'default': $static-white,
		'colored': $static-white,
		'dark': $static-white,
		'light': lighten($static-gray-800, 25%),
) !default;

$sidebar-header-padding: 1.5rem 1.5rem 0.375rem !default;
$sidebar-header-font-size: $font-size-sm !default;
$sidebar-header-color: (
		'default': $static-gray-400,
		'colored': $static-gray-400,
		'dark': $static-gray-400,
		'light': $static-gray-700,
) !default;

$sidebar-link-padding: 0.625rem 1.625rem !default;
$sidebar-link-font-weight: 400 !default;
$sidebar-link-color: (
		'default': rgba($static-gray-200, 0.5),
		'colored': rgba($static-gray-100, 0.7),
		'dark': rgba($static-gray-200, 0.5),
		'light': $static-gray-700,
) !default;

$sidebar-link-hover-font-weight: $font-weight-normal !default;
$sidebar-link-hover-color: (
		'default': #{rgba($static-gray-200, 0.75)},
		'colored': #{rgba($static-gray-100, 0.9)},
		'dark': #{rgba($static-gray-200, 0.75)},
		'light': #{lighten($primary, 5)},
) !default;

$sidebar-link-active-font-weight: $font-weight-normal !default;
$sidebar-link-active-color: (
		'default': $static-gray-200,
		'colored': $static-gray-100,
		'dark': $static-gray-200,
		'light': #{lighten($primary, 5)},
) !default;

$sidebar-link-child-padding: 0.55rem 1.5rem 0.55rem 3.7rem !default;
$sidebar-link-child-font-size: 90% !default;
$sidebar-link-child-font-weight: 400 !default;

$sidebar-link-child-color: (
		'default': $static-gray-500,
		'colored': $static-gray-400,
		'dark': $static-gray-500,
		'light': $static-gray-600,
) !default;
$sidebar-link-child-hover-color: (
		'default': $static-gray-200,
		'colored': $static-gray-100,
		'dark': $static-gray-200,
		'light': #{lighten($primary, 5)},
) !default;
$sidebar-link-child-active-color: (
		'default': #{lighten($primary, 5)},
		'colored': $white,
		'dark': #{lighten($primary, 5)},
		'light': #{lighten($primary, 5)},
) !default;

$sidebar-link-child-second-padding: 0.55rem 1.5rem 0.55rem 4.5rem !default;
$sidebar-link-child-third-padding: 0.55rem 1.5rem 0.55rem 5.75rem !default;

$sidebar-link-icon-color: (
		'default': #{rgba($static-gray-200, 0.5)},
		'colored': #{rgba($static-gray-100, 0.5)},
		'dark': #{rgba($static-gray-200, 0.5)},
		'light': $static-gray-700,
) !default;
$sidebar-link-icon-hover-color: (
		'default': #{rgba($static-gray-200, 0.75)},
		'colored': #{rgba($static-gray-100, 0.75)},
		'dark': #{rgba($static-gray-200, 0.75)},
		'light': #{lighten($primary, 5)},
) !default;
$sidebar-link-icon-active-color: (
		'default': $static-gray-200,
		'colored': $static-gray-100,
		'dark': $static-gray-200,
		'light': #{lighten($primary, 5)},
) !default;

$sidebar-badge-primary-color: (
		'default': $static-white,
		'colored': $dark,
		'dark': $static-white,
		'light': $static-white,
) !default;
$sidebar-badge-primary-bg: (
		'default': $primary,
		'colored': $static-white,
		'dark': $primary,
		'light': $primary,
) !default;

$sidebar-badge-secondary-color: (
		'default': $static-white,
		'colored': $static-dark,
		'dark': $static-white,
		'light': $static-white,
) !default;
$sidebar-badge-secondary-bg: (
		'default': $static-gray-600,
		'colored': rgba($static-white, 0.75),
		'dark': $static-gray-600,
		'light': $static-gray-600,
) !default;

$sidebar-compact-width: 72px;

$sidebar-compact-dropdown-padding: ($spacer / 2) 0;
$sidebar-compact-dropdown-width: 220px;
$sidebar-compact-dropdown-box-shadow: 0 0.5rem 3rem 0.5rem rgba($black, 0.05) !default;
$sidebar-compact-dropdown-border-radius: $border-radius-lg;
$sidebar-compact-dropdown-background: $white;

$sidebar-compact-dropdown-link-padding: 0.5rem 1.5rem;
$sidebar-compact-dropdown-link-color: $gray-600 !default;
$sidebar-compact-dropdown-link-hover-color: lighten($primary, 5) !default;
$sidebar-compact-dropdown-link-active-color: lighten($primary, 5) !default;

$sidebar-compact-dropdown-child-link-padding: 0.5rem 1.5rem 0.5rem 2.5rem;
$sidebar-compact-dropdown-child-second-link-padding: 0.5rem 1.5rem 0.5rem 3.5rem;

$sidebar-cta-color: (
		'default': $static-gray-200,
		'colored': $static-gray-200,
		'dark': $static-gray-200,
		'light': $static-gray-800,
) !default;

$sidebar-cta-bg: (
		'default': #{lighten(#293042, 5%)},
		'colored': #{lighten(#2d6bcf, 3%)},
		'dark': #{lighten(#293042, 5%)},
		'light': #{darken($static-white, 3%)},
) !default;

$sidebar-cta-padding: $spacer * 1.5 !default;
$sidebar-cta-margin: $spacer * 1.75 !default;
$sidebar-cta-border-radius: $border-radius-lg !default;

$transition-appearance: background 0.4s ease-in-out, color 0.4s ease-in-out !default;
$transition-appearance-slow: background 0.6s ease-in-out, color 0.6s ease-in-out !default;
$transition-appearance-fast: background 0.1s ease-in-out, color 0.1s ease-in-out !default;

$modal-content-border-width: 0 !default;
$modal-header-border-width: 1px !default;
$modal-footer-border-width: 1px !default;
$modal-xl: 1140px !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 400px !default;
$modal-transition: transform 0.25s ease-out !default;

$tab-content-padding: $spacer * 1.25 !default;
$tab-content-box-shadow: 0 0.2rem 0.1rem -0.1rem rgba(0, 0, 0, 0.15) !default;
$tab-content-border-radius: 0 0 $border-radius $border-radius !default;
$tab-link-color: $gray-800 !default;
$tab-link-padding: ($spacer * 0.75) $spacer !default;
$tab-link-bg: transparent !default;
$tab-link-active-color: $gray-800 !default;
$tab-link-active-bg: $white !default;

$badge-font-size: 80% !default;
$badge-padding-y: 0.3em !default;
$badge-padding-x: 0.45em !default;
$badge-pill-padding-x: 0.65em !default;

$pagination-active-bg: $hubbs !default;
$pagination-padding-y: 0.3rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.15rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.35rem !default;
$pagination-padding-x-lg: 1rem !default;
$pagination-color: $gray-600 !default;
$pagination-hover-color: $gray-800 !default;

$wrapper-border-color: $gray-300 !default;

$content-padding: 2.5rem 2.5rem 1.5rem !default;

$footer-background: $white !default;
$footer-border-top-color: $gray-300 !default;

$hamburger-width-top: 20px !default;
$hamburger-width-middle: 24px !default;
$hamburger-width-bottom: 16px !default;

