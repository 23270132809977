.navbar {
	border-bottom: $navbar-border-bottom;
	box-shadow: $navbar-box-shadow;
}

.input-group-navbar {
	min-width: 240px;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
	padding-right: 0;
	padding-left: 0;
}

.navbar-bg {
	background: $navbar-bg;
}

.navbar-align {
	margin-left: auto;
}

.navbar-brand {
	font-weight: $navbar-brand-font-weight;
	font-size: $navbar-brand-font-size;
	padding: $navbar-brand-padding-y $navbar-brand-padding-x;
	color: $navbar-brand-color;
	display: block;

	svg,
	.feather {
		color: $navbar-brand-icon-color;
		height: 24px;
		width: 24px;
		margin-left: -0.15rem;
		margin-right: 0.375rem;
		margin-top: -0.375rem;
	}
}

.nav-icon,
.nav-flag {
	padding: 0.1rem 0.8rem;
	display: block;
	font-size: 1.5rem;
	color: $gray-600;
	transition: $transition-appearance-fast;
	line-height: 1.4;

	&:after {
		display: none !important;
	}

	&:hover,
	&.active {
		color: $primary;
	}

	svg,
	.feather {
		width: 20px;
		height: 20px;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.navbar .avatar {
		max-height: 47px;
	}
}

@include media-breakpoint-down(sm) {
	.navbar {
		padding: 0.75rem;
	}

	.nav-icon {
		padding: 0.1rem 0.75rem;
	}

	.dropdown,
	.dropleft,
	.dropright,
	.dropup {
		position: inherit;
	}

	.navbar-expand .navbar-nav .dropdown-menu-lg {
		min-width: 100%;
	}

	.nav-item .nav-link:after {
		display: none;
	}
}

.nav-flag img {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.navbar-nav {
	direction: ltr;
}

.navbar input {
	direction: ltr;
}

.navbar .form-control {
	max-width: 200px;
}
