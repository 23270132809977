.card {
	margin-bottom: $grid-gutter-width;
	box-shadow: $card-shadow;
}

.card-dashboard {
	height: 240px;
}

.card-dashboard-lg {
	height: 264px;
}

.card-header {
  border-bottom-width: 1px;
}

.card-actions {
  a {
    color: $body-color;
    text-decoration: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .dropdown {
    line-height: 1.4;
  }
}

.card-title {
  font-size: $font-size-lg;
  font-weight: $card-title-font-weight;
  color: $card-title-color;
}

.card-subtitle {
  font-weight: $font-weight-normal;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
  padding-left: $card-spacer-x;
	
}

.card-table tr td:last-child,
.card-table tr th:last-child {
  padding-right: $card-spacer-x;
}

.card-img,
.card-img-top,
.card-img-bottom {
  @include img-fluid;

  @media all and (-ms-high-contrast:none) {
    height: 100%;
  }
}

.card-type-expiringcontracts {
  background-color: lighten($denouncedcompany, 30%);
}

.card-type-vacancy {
  background-color: lighten($vacancy, 10%);
}

.card-type-denouncedcompany  {
  background-color: lighten($denouncedcompany, 30%);
}

.card-type-tobedelivered  {
  background-color: lighten($tobedelivered, 10%);
}

.card-type-tobedeliveredrented  {
  background-color: lighten($tobedeliveredrented, 10%);
}

.card-type-denouncedcompanyrented  {
  background-color: lighten($denouncedcompany, 30%);
}

.card-type-denouncedowner  {
  background-color: lighten($denouncedrelation, 10%);
}

.card-type-totalrented  {
  background-color: lighten($rented, 10%);
}
