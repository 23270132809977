a {
	&.return {
		span {
			cursor: pointer;
			display: inline-block;
			position: relative;
			transition: 0.5s;

			&:after {
				content: '\2190';
				position: absolute;
				opacity: 0;
				top: 0;
				left: -20px;
				transition: 0.5s;
			}
		}

		&:hover {
			span {
				padding-left: 25px;

				&:after {
					opacity: 1;
					left: 0;
				}
			}
		}
	}
}
