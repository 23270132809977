.fs-lg {
  font-size: $font-size-lg;
}

.fs-sm {
  font-size: $font-size-lg;
}

.fs-7{
	font-size: 0.75rem;
}
