﻿.slider {
  margin-top: 0px !important;

  .bar {
	height: 17px !important;
	box-shadow: none !important;
	background: #f2f2f2 !important;

	.filler {
	  border: 1px solid $rented !important;
	  border-radius: 17px !important;

	  span {
		background: $rented !important;
		border-radius: 17px;
	  }

	  .step-indicators{
		span{
		  height: 17px !important;
		}
	  }

	}

	.left-handle {

	  .handle-tooltip {
		left: -5px !important;
	  }
	}

	.right-handle {
	  .handle-tooltip {
		left: -9px !important;
	  }
	}

	.left-handle,
	.right-handle {
	  top: 0 !important;
	  width: 17px !important;
	  height: 17px !important;
	  background: $rented !important;
	  border: 4px solid $hubbs !important;

	  .handle-tooltip {
		border: 1px solid $hubbs !important;
		background: $hubbs !important;
		color: #FFFFFF !important;
		font-weight: 500 !important;

		&:before,
		&:after {
		  border-top-color: $hubbs !important;
		}
	  }
	}
  }

  .values {
	display: none !important;
  }
}
