.btn-link:not(:hover) {
	text-decoration: none;
}

.btn-pill {
	border-radius: 10rem;
}

.btn-square {
	border-radius: 0;
}

.btn .feather {
	width: 14px;
	height: 14px;
}

.btn-lg .feather {
	width: 15px;
	height: 15px;
}

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-color($static-white);
		@include button-color-hover($static-white);
		@include button-color-active($static-white);
	}

	.btn-outline-#{$color} {
		@include button-color-hover($static-white);
		@include button-color-active($static-white);
	}
}

.btn-light,
.btn-white {
	@include button-color($gray-800);
	@include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
	@include button-color-hover($gray-800);
	@include button-color-active($gray-800);
}

.btn-hubbs-red {
	span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;

		&:after {
			content: '\2192';
			position: absolute;
			opacity: 0;
			top: 0;
			right: -10px;
			transition: 0.5s;
		}
	}

	&:hover {
		span {
			padding-right: 15px;
			margin-left: -15px;

			&:after {
				opacity: 1;
				right: -15px;
			}
		}
	}
}

.btn-hubbs-red-sync {
	span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;

		&:after {
			content: '\1F504';
			position: absolute;
			opacity: 0;
			top: 0;
			right: -10px;
			transition: 0.5s;
		}
	}

	&:hover {
		span {
			padding-right: 15px;
			margin-left: -15px;

			&:after {
				opacity: 1;
				right: -15px;
			}
		}
	}
}

.btn-hubbs-cancel {
	span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;

		&:after {
			content: '\2190';
			position: absolute;
			opacity: 0;
			top: 0;
			left: -20px;
			transition: 0.5s;
		}
	}

	&:hover {
		span {
			padding-right: 15px;
			margin-left: -15px;

			&:after {
				opacity: 1;
				left: -15px;
			}
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}
