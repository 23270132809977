@import 'ngx-toastr/toastr';
.toast-container {
	&.toast-bottom-full-width {
		.ngx-toastr {
			width: 100%;
			margin: 0;
			box-shadow: none;
			text-align: center;
			border-radius: 0;
			padding: 20px 0;
		}
	}
}
.toast-info,
.toast-error,
.toast-success,
.toast-warning {
	background-image: none;
}
