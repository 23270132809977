.loading-box {
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1052;
}

.loading-box,
.widget-loading-box {
	.loader,
	.loader:after {
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
	.loader {
		margin: 30px auto;
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: 1.1em solid #d8d8d8;
		border-right: 1.1em solid #d8d8d8;
		border-bottom: 1.1em solid #d8d8d8;
		border-left: 1.1em solid $hubbs;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load 1.1s infinite linear;
		animation: load 1.1s infinite linear;
	}

	@-webkit-keyframes load {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
}
