@media (max-width: 499px) {
	.mobile-hidden {
		display: none;
	}
}

@media (min-width: 500px) {
	.mobile-only {
		display: none;
	}
}