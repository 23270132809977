
.form-floating {
  &.switch {

	border-color: #ced4da !important;
	border-radius: 5px;

	label {
	  opacity: 0.65;
	  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
	}

	div.form-switch {
	  padding-top: 2.625rem;
	  padding-bottom: 0.625rem;

	  .form-check-input {
		margin-left: -8px !important;
		margin-top: -10px !important;
	  }
	}

	.form-check-input {
	  &:checked {
		background-color: $hubbs-light;
		border-color: $hubbs-light;
	  }
	}
  }
}

.form-control,
.ng-select {
  &:disabled,
  &.ng-select-disabled {
	border: none;
	background-color: #ffffff;
	// padding-left: 0px;
  }
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.input-group-navbar {
  .form-control,
  .btn {
	height: $input-height-lg;
	background: $navbar-input-bg;
	box-shadow: none;
	border: 0;
	padding: $input-padding-y-lg $spacer * 0.75;

	&:focus {
	  background: $navbar-input-bg;
	  box-shadow: none;
	  outline: 0;
	}
  }

  .btn {
	color: $gray-600;

	.feather {
	  width: 20px;
	  height: 20px;
	}
  }
}

.filter-select {
  padding-top: 10px;
  padding-bottom: 10px;

  .ng-dropdown-panel {
	background-color: map-get($theme-colors, 'secondary');
	border: none;

	&.ng-select-top {
	  bottom: calc(100% + 11.25px);
	}

	&.ng-select-bottom {
	  top: calc(100% + 11.25px);
	}
  }

  .ng-value-container {
	padding: 0 8px;
  }
}

.ng-dropdown-panel {
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  left: -1px;
  width: calc(100% + 2px) !important;
  line-height: 38px;
  margin-top: -2px;

  &.ng-select-top {
	bottom: 100%;
	border-bottom: none;
  }

  &.ng-select-bottom {
	top: 100%;
	border-top: none;
  }

  .ng-option {
	padding: 0 16px;

	&:hover {
	  background-color: rgba(0, 0, 0, 0.1);
	}

	&.ng-option-selected {
	  font-weight: 500;
	  background-color: rgba(0, 0, 0, 0.1);
	}

	&.ng-option-child {
	  padding: 0 20px;
	}
  }

  .ng-optgroup {
	padding: 0 16px;
	font-weight: bold;
  }
}

.is-invalid {
  .ng-dropdown-panel {
	background-color: map-get($theme-colors, 'white');
	border: 1px solid map-get($theme-colors, 'danger');

	&.ng-select-top {
	  border-bottom: none;
	}

	&.ng-select-bottom {
	  border-top: none;
	}
  }
}

.ng-has-value {
  .ng-placeholder {
	display: none;
  }
}

.ng-value {
  margin-right: 10px;
  padding: 0;
  background-color: map-get($theme-colors, 'primary-transparent');

  .ng-value-icon {
	margin-right: 5px;
  }
}

.filter-select {
  .ng-value {
	background-color: transparent;
	font-weight: bold;
  }
}

.ng-arrow-wrapper {
  width: 16px;

  .ng-arrow {
	border: solid #666865;
	border-width: 0 1px 1px 0;
	display: inline-block;
	margin-bottom: 3px;
	padding: 4px;
	transform: rotate(45deg);
  }
}

.ng-select-opened {
  .ng-arrow {
	margin-bottom: -2px;
	transform: rotate(225deg);
  }
}

.ng-select-single {
  .ng-value {
	padding: 1px 0 0;
	background-color: transparent;
  }
}

.ng-select-disabled {
  background-color: #e9ecef;
}

.ng-option-disabled {
  background-color: #e9ecef !important;
  cursor: default !important;
}

.form-control.ng-select-multiple {
  padding: 0;

  .ng-select-container {
	// padding: 0.25rem 1rem 0.25rem 0.7rem;
	align-items: center;

	.ng-value-container {
	  .ng-placeholder {
		padding: 0.25rem;
	  }

	  .ng-value {
		// margin: 0.25rem;
	  }

	  .ng-input {
		margin-top: 0.25rem;
	  }
	}
  }
}

label {
  span {
	color: $hubbs-red;
  }
}


.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
	height: $form-floating-height;
	padding: $form-floating-padding-y $form-floating-padding-x;
  }

  > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: $form-floating-padding-y $form-floating-padding-x;
	pointer-events: none;
	border: $input-border-width solid transparent;
	transform-origin: 0 0;
	@include transition($form-floating-transition);
  }

  > .form-control {
	&::placeholder {
	  color: transparent;
	}

	&:focus,
	&:not(:placeholder-shown) {
	  padding-top: $form-floating-input-padding-t;
	  padding-bottom: $form-floating-input-padding-b;
	}

	&:-webkit-autofill {
	  padding-top: $form-floating-input-padding-t;
	  padding-bottom: $form-floating-input-padding-b;
	}
  }

  > .form-select {
	padding-top: $form-floating-input-padding-t;
	padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select,
  > .form-range {
	~ label {
	  opacity: $form-floating-label-opacity;
	  transform: $form-floating-label-transform;
	}
  }

  > .form-control:-webkit-autofill {
	~ label {
	  opacity: $form-floating-label-opacity;
	  transform: $form-floating-label-transform;
	}
  }
}
