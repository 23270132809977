@each $color, $value in $custom-colors {
	@include bg-variant('.bg-#{$color}', $value, true);
}

.bg-hubbs-image {
	background-size: cover;
	background-image: url('/assets/images/background-image.jpg');
}

.bg-overlay-image {
	position: relative;
	background-color: rgba(0,0,0,0.5);
}
